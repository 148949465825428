<template>
  <div class="columns is-multiline">
    <div class="column is-4" v-if="!fullwidthTimeline">
      <div class="columns is-multiline">
        <div class="column is-12" v-if="showListingModule">
          <CompanionListingModule v-bind="{ listing, pricePosition, type }" />
        </div>
        <div class="column is-12">
          <VehicleSpec v-bind="{ vrm, preload: specs }" />
        </div>
      </div>
    </div>
    <div class="column" :class="[fullwidthTimeline ? 'is-12' : 'is-8']">
      <div
        class="columns is-fullheight"
        :class="{ 'pl-5': $mq !== 'mobile' && !isAutoCheck }"
      >
        <div class="column is-12">
          <div>
            <div class="mb-5" v-if="!fullwidthTimeline">
              <span class="has-text-dark has-text-weight-semibold">
                <span class="is-valigned">Timeline</span>
              </span>
            </div>
            <hr class="mt-0 has-background-dark" v-if="!isAutoCheck" />
            <div v-if="timeline === null">
              <Spinner />
            </div>
            <TableEmpty
              v-if="timeline === false"
              class="is-fullheight"
              text="No timeline available"
              :subtext="`We currently can't surface any data for ${vrm}`"
            />
            <div class="columns is-multiline" v-else-if="timeline">
              <div
                class="column is-12"
                v-for="(event, index) in timeline.events"
                :key="index"
              >
                <component
                  :is="getComponent(event.type)"
                  v-if="getComponent(event.type)"
                  v-bind="{ event, vrm }"
                />
                <div v-else>{{ event.type }}</div>
                <hr class="has-background-soft mb-0" />
              </div>
              <div class="column is-12">
                <Registered :event="timeline.firstRegistration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTimeline } from '../services'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
export default {
  name: 'VehicleInfoTimeline',
  data: () => ({
    timeline: null
  }),
  props: {
    load: {
      type: [Object, Boolean],
      required: false,
      default: () => null
    },
    vrm: {
      type: String,
      default: () => '',
      required: false
    },
    vin: {
      type: String,
      default: () => '',
      required: false
    }
  },
  components: {
    Listing: () =>
      import('modules/companion/components/timeline/TimelineListing'),
    Owner: () => import('modules/companion/components/timeline/TimelineOwner'),
    Plate: () => import('modules/companion/components/timeline/TimelinePlate'),
    Registered: () =>
      import('modules/companion/components/timeline/TimelineRegistered'),
    Mot: () => import('modules/companion/components/timeline/TimelineMot'),
    Spinner: () => import('core/components/Spinner'),
    TableEmpty: () => import('core/components/TableEmpty'),
    CompanionListingModule: () =>
      import('modules/companion/components/section/CompanionListingModule'),
    VehicleSpec: () => import('./VehicleSpec')
  },
  computed: {
    ...mapGetters('auth', ['permissions']),
    ...mapGetters('companion', ['specs', 'listing', 'pricePosition', 'type']),
    showListingModule() {
      return this.permissions.currentListing && !isEmpty(this.listing)
    },
    fullwidthTimeline() {
      return ['autocheck', 'vehicle-profile'].includes(this.$route.name)
    },
    isAutoCheck() {
      return this.$route.name === 'autocheck'
    }
  },
  methods: {
    getComponent(type) {
      const components = {
        Mot: 'Mot',
        Sale: 'Listing',
        KeeperChange: 'Owner',
        PlateChange: 'Plate'
      }

      return components[type]
    },
    async fetch() {
      try {
        this.timeline = await getTimeline(this.vin)
      } catch (err) {
        this.timeline = false
        this.$notify('Timeline unavailable')
      }
    }
  },
  watch: {
    vin(value) {
      if (value && !this.load) this.fetch(value)
    },
    load(value) {
      this.timeline = value
    }
  },
  mounted() {
    if (this.load || this.load === false) {
      this.timeline = this.load
    } else if (this.vin && !this.timeline) {
      this.fetch()
    }
  }
}
</script>
